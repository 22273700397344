<template>
<div style="width:100%">
  <v-list-item link class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" md="1">
        <v-row no-gutters align="center">
            <v-col cols="12" md="3">
              <!-- <v-card width="25" height="85" color="pink" @click="abrirColores"></v-card> -->
            </v-col>
            <v-col cols="12" md="9" style="font-size:12px" class="text-center">
              {{ item.codigo }}
            </v-col>
        </v-row>
          <!-- <v-btn icon color="pink"><v-icon>mdi-heart</v-icon></v-btn> -->
      </v-col>
      <v-col cols="12" md="2">
        <v-list-item-content class="pa-0">
            <v-list-item-title v-if="item.aplicativo"><v-icon size="15"> {{ item.aplicativo.icono }} </v-icon><span style="font-size:10px" class="pl-2">{{ item.aplicativo.descripcion }}</span></v-list-item-title>
            <v-list-item-title v-if="item.gescliente"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescliente.identificacion }}</span></v-list-item-title>
            <v-list-item-title v-if="item.gescliente"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescliente.nombre }}</span></v-list-item-title>
            <v-list-item-title v-if="item.gescliente"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ (item.gescliente.sexo)?item.gescliente.sexo.descripcion:'' }}</span></v-list-item-title>
        </v-list-item-content>
      </v-col>
      <v-col cols="12" md="3">
          <v-list-item-content class="pa-0">
              <v-list-item-title v-if="item.tipo"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.tipo.descripcion }}</span></v-list-item-title>
              <v-list-item-title v-if="item.motivo"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.motivo.descripcion }}</span></v-list-item-title>
              <v-list-item-title v-if="item.submotivo"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.submotivo.descripcion }}</span></v-list-item-title>
              <v-list-item-title v-if="item.canal"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.canal.descripcion }}</span></v-list-item-title>
          </v-list-item-content>
      </v-col>
      <v-col cols="12" md="3">
            <v-row no-gutters><v-col class="pl-2"><span class="pl-1" style="font-size:9px"><v-chip x-small :color="item.subestado.color" dark>ESTADO: <span class="pr-2"></span> {{ item.subestado.descripcion }} </v-chip></span></v-col></v-row>
            <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">{{ item.fecha_requerimiento }}</span></v-col></v-row>
            <v-row no-gutters v-if="item.usuario"><v-col class="pl-2"><v-icon size="15">mdi-account-circle</v-icon><span class="pl-1" style="font-size:10px">{{ item.usuario.nombre_usuario + '' + item.usuario.apellido_usuario }}</span></v-col></v-row>
            <v-row no-gutters v-if="item.fechaparacerrarexpres || item.fechaparacerrarinterno"><v-col class="pr-2"><v-card class="pl-2" color="green" dark><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRAR {{ (item.quejaexpres_id===29)? ((item.fechaparacerrarexpres) ? item.fechaparacerrarexpres.substr(0, 10) : null): ((item.fechaparacerrarinterno) ? item.fechaparacerrarinterno.substr(0, 10) : null ) }}</span></v-card></v-col></v-row>
      </v-col>
      <v-col cols="12" md="3" align-self="center">
          <v-row no-gutters align="center">
            <v-col cols="12" md="10">
                <v-row no-gutters class="pt-1" v-for="(itemEsc, indexEsc) in item.escalamientos" :key="indexEsc">
                  <v-col>
                    <v-chip small outlined color="green">
                      <v-avatar left><v-icon size="15">mdi-check-bold</v-icon></v-avatar>
                      <span style="font-size:9px">{{ itemEsc.escaladoa.nombre_usuario + '' + itemEsc.escaladoa.apellido_usuario }}</span>
                    </v-chip>
                  </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="2" align-self="center" class="text-center">
              <v-btn @click="ver_pqr(item)" icon color="blue"><v-icon>mdi-pencil</v-icon></v-btn>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
  </v-list-item>
  <div style="height:5px"></div>
  <v-divider></v-divider>
  <div style="height:5px"></div>
  </div>
</template>

<script>

export default {
  name: 'bandeja_listados_correosComponent',
  components: {

  },
  props: ['colores', 'item', 'session'],
  data: () => ({

  }),
  methods: {
    ver_pqr (pItem) {
      this.$emit('view_correo', pItem)
    },
    abrirColores () {

    }
  }
}
</script>
<style scoped>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes parpadeo{
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
